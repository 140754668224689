import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import Signup from "../../assets/img/signupintro.png";
import QrCode from "../../assets/img/qrcodeintro.png";
import Envelope from "../../assets/img/newslettersintro.png";
import Contact from "../../assets/img/contactintro.png";
import "./Introbutons.scss";

const Introbutons = () => {
  const { auth } = useAppSelector((state) => state.auth);

  return (
    <div className="introbutons">
      {auth?.token !== "" ? (
        auth?.user?.is_subscribed ? (
          <Link className="introbtn" to="qr-code">
            <img src={QrCode} alt="qrcode" />
            QR-code
          </Link>
        ) : null
      ) : (
        <Link className="introbtn" to="sign-up">
          <img src={Signup} alt="signup" />
          Sign Up
        </Link>
      )}
      <Link
        className="introbtn"
        to="https://kommunitykoin.com/newsletter"
        target="_blank"
      >
        <img src={Envelope} alt="newsletter" />
        Get Newsletter
      </Link>
      <Link
        className="introbtn"
        to="https://kommunitykoin.com/contact%2Ffeedback"
        target="_blank"
      >
        <img src={Contact} alt="contact" />
        Contact
      </Link>
    </div>
  );
};

export default Introbutons;
