import { createSlice } from '@reduxjs/toolkit';

import {
  authentication,
  checkSMS,
  clearNotifications,
  confirmUser,
  editUser,
  forgotPassword,
  getNotification,
  getNotifications,
  getTransferUser,
  getUser,
  logout,
  register,
  resetPassword,
  sendConfirmationCode,
  updatePaymentMethod,
  updateUserKoins
} from './authThunk';

type AuthState = {
  auth: any;
  notifications: any;
  notification: any;
  loading: boolean;
  error: any;
  success: boolean;
  transferUser: any;
  firstLogin: boolean;
};

const initialState: AuthState = {
  auth: {
    token: localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token') ?? '')
      : '',
    user: localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') ?? '')
      : ''
  },
  firstLogin: false,
  transferUser: null,
  notification: null,
  notifications: [],
  loading: false,
  error: null,
  success: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(authentication.fulfilled, (state, { payload }) => {
        state.auth = payload;
        payload.token &&
          localStorage.setItem('token', JSON.stringify(payload.token));
        payload.uuid &&
          localStorage.setItem('uuid', JSON.stringify(payload.uuid));
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(authentication.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authentication.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        // state.auth = payload;
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(register.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(logout.fulfilled, state => {
        state.auth = { token: '', user: '' };
        state.firstLogin = false;
        localStorage.removeItem('token');
        localStorage.removeItem('uuid');
        localStorage.removeItem('user');
        localStorage.removeItem('phone');
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(confirmUser.fulfilled, state => {
        state.firstLogin = true;
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(confirmUser.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(sendConfirmationCode.fulfilled, state => {
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(sendConfirmationCode.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(checkSMS.fulfilled, state => {
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(checkSMS.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        return state;
      })

      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        return state;
      })
      .addCase(resetPassword.rejected, (state, action: any) => {
        state.error = action.payload?.response?.data?.errors;
        state.loading = false;
        return state;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.auth = { ...state.auth, user: payload?.user };
        payload?.user &&
          localStorage.setItem('user', JSON.stringify(payload?.user));

        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(getTransferUser.fulfilled, (state, { payload }) => {
        state.transferUser = payload?.user;
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(getTransferUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        if (payload?.message?.data?.length > 0 && payload?.is_new) {
          state.notifications = [
            ...payload?.message?.data,
            ...state.notifications
          ];
        } else {
          state.notifications = payload?.message?.data;
        }
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(getNotification.fulfilled, (state, { payload }) => {
        state.notification = payload?.message;
        state.loading = false;
        state.success = true;
        return state;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(updateUserKoins.fulfilled, (state, { payload }) => {
        state.auth.user.koins = payload;
        return state;
      })
      .addCase(updateUserKoins.rejected, (state, action) => {
        return state;
      })
      .addCase(clearNotifications.fulfilled, (state, { payload }) => {
        state.notifications = [];
        return state;
      })
      .addCase(clearNotifications.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      })

      .addCase(updatePaymentMethod.fulfilled, state => {
        return state;
      })
      .addCase(updatePaymentMethod.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        return state;
      });
  }
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;
