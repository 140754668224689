import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Envelope from '../../assets/svg/EnvelopeOpen.svg';
import Smiley from '../../assets/svg/Smiley.svg';
import Lock from '../../assets/svg/Lock.svg';
import Card from '../../assets/svg/CreditCard.svg';
import Unsubscribe from '../../assets/svg/unsubscribe.svg';
import './Profile.scss';
import { hasError, notifySuccess } from '../../assets/helpers/helpers';
import {
  clearTemporaryEmail,
  editUser,
  getUser
} from '../../redux/auth/authThunk';
import WarningIcon from '../../assets/svg/Warning.svg';
import { useCardBrand, images } from 'react-card-brand';

interface Props {}

const Profile: React.FC<Props> = () => {
  const { auth } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    email: '',
    full_name: '',
    phone: ''
  });
  const [errors, setErrors] = useState([]);
  const { getSvgProps } = useCardBrand();

  useEffect(() => {
    setFormData({
      full_name: auth?.user?.full_name,
      email: auth?.user?.email,
      phone: auth?.user?.phone
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user]);

  const handleChange = (e: any) => {
    e.preventDefault();
    if (
      auth?.user?.email !== formData.email ||
      auth?.user?.full_name !== formData.full_name
    ) {
      dispatch(editUser(formData)).then((res: any) => {
        if (res.payload?.status === 'OK') {
          dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token }));
          if (auth?.user?.email !== formData.email) {
            notifySuccess(
              'The update to your account is pending. Please confirm your new email.'
            );
          } else {
            navigate('/profile-updated');
          }
        }
      });
    }
  };

  const cancelEmail = () => {
    dispatch(clearTemporaryEmail()).then(res => {
      if (res.payload.status === 'OK') {
        dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token }));
      }
    });
  };

  return (
    <div className='profile-container'>
      <div className='form-conatiner'>
        <h4 className='title'>Profile</h4>
        <form onSubmit={handleChange}>
          <div
            className={`inputs-field ${
              hasError(errors, 'full_name') ? 'error' : ''
            }`}
          >
            <img src={Smiley} alt='' />
            <input
              placeholder='Full Name'
              type='text'
              id='name'
              value={formData.full_name}
              onChange={e => {
                setFormData({
                  ...formData,
                  full_name: e.target.value
                });
                setErrors((prev: any) =>
                  prev?.filter((err: any) => err.key !== 'full_name')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'email') ? 'error' : ''
            }`}
          >
            <img src={Envelope} alt='' />
            <input
              placeholder='Email Address'
              type='text'
              id='email'
              value={formData.email}
              onChange={e => {
                setFormData({
                  ...formData,
                  email: e.target.value.toLocaleLowerCase()
                });
                setErrors((prev: any) =>
                  prev?.filter((err: any) => err.key !== 'email')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'phone') ? 'error' : ''
            }`}
          >
            <img src={Envelope} alt='' />
            <input
              placeholder='Phone Number'
              type='text'
              id='phone'
              value={formData.phone}
              readOnly
              disabled
            />
          </div>
          {auth?.user?.temporary_email && (
            <div className='cancel-btn'>
              <div className='text-btn'>
                <img src={WarningIcon} alt='' />
                <span>
                  A request to update the profile email was sent to{' '}
                  {auth?.user?.temporary_email}.<br />
                  If you want to cancel the request, please click the cancel
                  button.
                </span>
              </div>
              <div
                className='linear-gradient-button'
                onClick={() => cancelEmail()}
              >
                <button type='submit' className='submit-button'>
                  Cancel
                </button>
              </div>
            </div>
          )}
          <Link to={'/change-password'} className='change-btn'>
            <img src={Lock} alt='' />
            Change Password
          </Link>

          {auth?.user?.is_subscribed && (
            <Link
              className='change-btn change-payment'
              to={'/change-payment-method'}
            >
              <div className='action-info'>
                <img src={Card} alt='' />
                Change Payment Method
              </div>

              {auth?.user?.card_type && auth?.user?.card_number && (
                <div className='card-details'>
                  <svg
                    {...getSvgProps({
                      type: auth?.user?.card_type.toLocaleLowerCase(),
                      images
                    })}
                  />{' '}
                  Ending in {auth?.user?.card_number}
                </div>
              )}
            </Link>
          )}

          <div className='profile-separator'></div>
          <div className='profile-submit-container'>
            <div className='linear-gradient-button'>
              <button
                type='submit'
                className='submit-button'
                disabled={
                  !(
                    auth?.user?.email !== formData.email ||
                    auth?.user?.full_name !== formData.full_name
                  )
                }
              >
                Save
              </button>
            </div>

            {auth?.user?.is_subscribed && (
              <div className='bottom'>
                <div>
                  <Link to={'/privacy'} className='privacy-policy'>
                    Privacy Policy
                  </Link>
                  <Link className='terms' to={'/terms'}>
                    Terms and Conditions
                  </Link>
                </div>
                <button
                  onClick={() => navigate('/unsubscribe')}
                  className='unsubscribe-btn'
                >
                  <img src={Unsubscribe} alt='' />
                  Unsubscribe
                </button>
              </div>
            )}
          </div>
          <div className='profile-separator'></div>
          <Link className='logout-btn' to={'/logout'}>
            Log Out
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Profile;
