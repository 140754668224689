import { Link, useNavigate } from 'react-router-dom';
import React, { useState, FormEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { register } from '../../redux/auth/authThunk';
import { hasError, notify } from '../../assets/helpers/helpers';
import Envelope from '../../assets/svg/EnvelopeOpen.svg';
import Lock from '../../assets/svg/Lock.svg';
import SubmitIcon from '../../assets/svg/ArrowUpRight.svg';
import Smiley from '../../assets/svg/Smiley.svg';
import ShieldCheck from '../../assets/svg/ShieldCheck.svg';
import ShieldCevron from '../../assets/svg/ShieldChevron.svg';
import background from '../../assets/svg/signupbg.svg';
import './SignUp.scss';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface Props {}

interface SignUpForm {
  name: string;
  email: string;
  confirmEmail: string;
  phone: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}

const SignUp: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector((state: any) => state.auth);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState<SignUpForm>({
    name: '',
    email: '',
    confirmEmail: '',
    phone: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.acceptTerms) {
      dispatch(
        register({
          email: formData.email,
          confirm_email: formData.confirmEmail,
          password: formData.password,
          phone: formData.phone,
          confirm_password: formData.confirmPassword,
          full_name: formData.name
        })
      ).then(res => {
        if (res.payload?.status === 'OK') {
          localStorage.setItem('phone', formData.phone);
          localStorage.setItem('email', formData.email);

          setFormData({
            name: '',
            email: '',
            confirmEmail: '',
            phone: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false
          });
          navigate('/confirm-code');
        }
        setErrors(typeof error === 'string' ? [error] : error);
      });
    } else notify('Accept terms is required!');
  };

  return (
    <div className='sign-up-container'>
      <img className='signupbgimg' src={background} alt='background' />
      <div className='form-conatiner'>
        <h4 className='title'>Sign Up</h4>
        <form onSubmit={handleSubmit}>
          <div
            className={`inputs-field ${
              hasError(errors, 'full_name') ? 'error' : ''
            }`}
          >
            <img src={Smiley} alt='' />
            <input
              placeholder='Full Name'
              type='text'
              id='name'
              value={formData.name}
              onChange={e => {
                setFormData({
                  ...formData,
                  name: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'full_name')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'email') ? 'error' : ''
            }`}
          >
            <img src={Envelope} alt='' />
            <input
              placeholder='Email Address'
              type='text'
              id='email'
              value={formData.email}
              onChange={e => {
                setFormData({
                  ...formData,
                  email: e.target.value.toLocaleLowerCase()
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'email')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'confirm_email') ? 'error' : ''
            }`}
          >
            <img src={ShieldCheck} alt='' />
            <input
              placeholder='Confirm Email Address'
              type='text'
              id='emailconfirm'
              value={formData.confirmEmail}
              onChange={e => {
                setFormData({
                  ...formData,
                  confirmEmail: e.target.value.toLocaleLowerCase()
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'confirm_email')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'phone') ? 'error' : ''
            }`}
          >
            <PhoneInputWithCountrySelect
              value={formData.phone}
              defaultCountry='US'
              international
              countryCallingCodeEditable={false}
              onChange={e => {
                setFormData({
                  ...formData,
                  phone: e ?? ''
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'phone')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'password') ? 'error' : ''
            }`}
          >
            <img src={Lock} alt='' />
            <input
              autoComplete=''
              placeholder='Password'
              type='password'
              id='password'
              value={formData.password}
              onChange={e => {
                setFormData({
                  ...formData,
                  password: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'password')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'confirm_password') ? 'error' : ''
            }`}
          >
            <img src={ShieldCevron} alt='' />
            <input
              autoComplete=''
              placeholder='Confirm Password'
              type='password'
              id='confirmpassword'
              value={formData.confirmPassword}
              onChange={e => {
                setFormData({
                  ...formData,
                  confirmPassword: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'confirm_password')
                );
              }}
            />
          </div>
          <div className='terms-conditions'>
            <label className='custom-checkbox'>
              I have read and accept the{' '}
              <Link to='/terms'>Terms and Conditions</Link>
              <input
                type='checkbox'
                checked={formData.acceptTerms}
                onChange={e =>
                  setFormData({
                    ...formData,
                    acceptTerms: e.target.checked
                  })
                }
              />
              <span className='checkmark'></span>
            </label>
          </div>
          <div className='linear-gradient-button'>
            <button type='submit' className='submit-button'>
              Sign Up
              <img src={SubmitIcon} alt='' />
            </button>
          </div>
        </form>
        <p className='privacy-policy'>
          See our <Link to='/privacy'>Privacy Policy</Link> for more details
        </p>
      </div>
    </div>
  );
};

export default SignUp;
