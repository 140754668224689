import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { hasError, notifySuccess } from '../../assets/helpers/helpers';
import Lock from '../../assets/svg/Lock.svg';
import ArrowBack from '../../assets/svg/arrowback.svg';
import Shield from '../../assets/svg/shield-chevron.svg';
import SubmitIcon from '../../assets/svg/ArrowRight.svg';
import './ChangePassword.scss';
import { changePassword, logout } from '../../redux/auth/authThunk';

interface Props {}

const ChangePassword: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    current_password: '',
    password: '',
    confirm_password: ''
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (e: any) => {
    e.preventDefault();

    dispatch(changePassword(formData)).then((res: any) => {
      if (res.payload?.status === 'OK') {
        notifySuccess(res.payload?.message);
        setTimeout(() => {
          dispatch(logout()).then(() => navigate('/login'));
        }, 2000);
      }
    });
  };

  return (
    <div className='change-password-container'>
      <div className='back-btn' onClick={() => navigate('/profile')}>
        <img src={ArrowBack} alt='' />
      </div>
      <div className='change-password-top'>
        <div className='change-password-title'>
          Change <br />
          Password
        </div>
      </div>

      <div className='form-conatiner'>
        <form onSubmit={handleChange}>
          <div
            className={`inputs-field ${
              hasError(errors, 'current_password') ? 'error' : ''
            }`}
          >
            <img src={Lock} alt='' />
            <input
              placeholder='Current Password'
              type='password'
              id='current_password'
              value={formData.current_password}
              onChange={e => {
                setFormData({
                  ...formData,
                  current_password: e.target.value
                });
                setErrors((prev: any) =>
                  prev?.filter((err: any) => err.key !== 'current_password')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'password') ? 'error' : ''
            }`}
          >
            <img src={Lock} alt='' />
            <input
              placeholder='New Password'
              type='password'
              id='password'
              value={formData.password}
              onChange={e => {
                setFormData({
                  ...formData,
                  password: e.target.value
                });
                setErrors((prev: any) =>
                  prev?.filter((err: any) => err.key !== 'password')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'confirm_password') ? 'error' : ''
            }`}
          >
            <img src={Shield} alt='' />
            <input
              placeholder='Confirm Password'
              type='password'
              id='confirm_password'
              value={formData.confirm_password}
              onChange={e => {
                setFormData({
                  ...formData,
                  confirm_password: e.target.value
                });
                setErrors((prev: any) =>
                  prev?.filter((err: any) => err.key !== 'confirm_password')
                );
              }}
            />
          </div>

          <div className='linear-gradient-button'>
            <button type='submit' className='submit-button'>
              Next
              <img src={SubmitIcon} alt='' />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
