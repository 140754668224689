import AccountMessage from '../../components/AccountMessage';
import UnsubscribeBg from '../../assets/img/unsubscribebg.png';
import './SuccessUnsubscribe.scss';

interface Props {}

const SuccessUnsubscribe: React.FC<Props> = () => {
  return (
    <AccountMessage
      title={'Successfully Unsubscribed'}
      desc={
        'You will no longer be billed the subscription cost or have access to your KoinPurse.'
      }
      link='/koin-purse'
      img={UnsubscribeBg}
    />
  );
};

export default SuccessUnsubscribe;
