import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  checkSMS,
  getUser,
  sendConfirmationCode
} from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ArrowBack from '../../assets/svg/arrowback.svg';
import './ConfirmCode.scss';
import Unsubscribe from '../../assets/svg/unsubscribe.svg';
import { notifySuccess } from '../../assets/helpers/helpers';

interface Props {}

const ConfirmCode: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth, firstLogin } = useAppSelector(state => state.auth);
  let one = useRef<any>(null);
  let two = useRef<any>(null);
  let three = useRef<any>(null);
  let four = useRef<any>(null);
  let five = useRef<any>(null);
  let six = useRef<any>(null);
  const [confirmationCode, setConfirmationCode] = useState({
    nr0: '',
    nr1: '',
    nr2: '',
    nr3: '',
    nr4: '',
    nr5: ''
  });

  const changeFocus = (e: React.ChangeEvent) => {
    switch ((e.target as HTMLInputElement)?.id) {
      case 'input-1':
        two.current && two.current?.focus();
        break;
      case 'input-2':
        three.current?.focus();
        break;
      case 'input-3':
        four.current?.focus();
        break;

      case 'input-4':
        five.current?.focus();
        break;
      case 'input-5':
        six.current?.focus();
        break;
      case 'input-6':
        one.current?.focus();
        break;

      default:
        break;
    }
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const pasted = event.clipboardData.getData('text/plain');
    const array = pasted.split('').slice(0, 6);
    setConfirmationCode({
      nr0: array[0],
      nr1: array[1],
      nr2: array[2],
      nr3: array[3],
      nr4: array[4],
      nr5: array[5]
    });
  };

  const sendCode = (e: any) => {
    e.preventDefault();

    dispatch(
      checkSMS({
        code:
          confirmationCode.nr0 +
          confirmationCode.nr1 +
          confirmationCode.nr2 +
          confirmationCode.nr3 +
          confirmationCode.nr4 +
          confirmationCode.nr5,
        phone: auth?.user?.phone ?? localStorage.getItem('phone') ?? ''
      })
    ).then((res: any) => {
      if (res.payload?.status === 'OK') {
        notifySuccess('Phone number was successfully confirmed!');
        dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token })).then(
          () => {
            auth?.token !== ''
              ? firstLogin
                ? navigate('/koin-purse')
                : navigate('/')
              : navigate('/confirm-email');
          }
        );
      }
    });
  };

  const getCode = (e: any) => {
    e.preventDefault();

    dispatch(
      sendConfirmationCode({
        phone: localStorage?.getItem('phone') ?? '',
        email: localStorage?.getItem('email') ?? ''
      })
    );
  };

  return (
    <>
      <div className='confirm-code-container'>
        {auth?.user?.email && (
          <div className='back-btn' onClick={() => navigate('/confirm-phone')}>
            <img src={ArrowBack} alt='' />
          </div>
        )}
        <div className='confirm-code-message-top'>
          <div className='confirm-code-message-title'>
            <span>
              {auth?.user?.email ? 'Activate account' : 'Confirm Phone Number'}{' '}
            </span>{' '}
          </div>
          <div className='confirm-code-message-text'>
            Your Phone Number:
            <br />
            {auth?.user?.phone ?? localStorage.getItem('phone')}
          </div>
        </div>

        <div className='confirm-code-message-separator'></div>
        <form className='confirm-code-message-bottom'>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${1}`}
              ref={one}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr0}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr0: e.target.value
                });
              }}
            />
          </div>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${2}`}
              ref={two}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr1}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr1: e.target.value
                });
              }}
            />
          </div>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${3}`}
              ref={three}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr2}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr2: e.target.value
                });
              }}
            />
          </div>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${4}`}
              ref={four}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr3}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr3: e.target.value
                });
              }}
            />
          </div>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${5}`}
              ref={five}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr4}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr4: e.target.value
                });
              }}
            />
          </div>
          <div className='inputs-field '>
            {' '}
            <input
              onPaste={onPaste}
              id={`input-${6}`}
              ref={six}
              type='number'
              min={0}
              max={9}
              maxLength={1}
              pattern='\d*'
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.slice(
                    e.target.value.length - 1,
                    e.target.value.length
                  );
                changeFocus(e);
              }}
              value={confirmationCode?.nr5}
              onChange={e => {
                setConfirmationCode({
                  ...confirmationCode,
                  nr5: e.target.value
                });
              }}
            />
          </div>
        </form>

        <div className='btn-section'>
          <div className='continue-btn' onClick={e => sendCode(e)}>
            {auth?.user?.email ? 'Activate' : 'Send'}
          </div>
        </div>
        <button onClick={e => getCode(e)} className='unsubscribe-btn'>
          <img src={Unsubscribe} alt='' />
          Resend code
        </button>
      </div>{' '}
    </>
  );
};

export default ConfirmCode;
