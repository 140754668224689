import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logout } from '../../redux/auth/authThunk';
import Welcome from '../../components/Welcome';
import Introbutons from '../../components/Introbutons';
import background from '../../assets/img/homebg.png';
import './Home.scss';

interface Props {}

const Home: React.FC<Props> = () => {
  const { auth } = useAppSelector(state => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname === '/logout') {
      dispatch(logout());
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (auth?.user?.uuid && !auth?.user?.is_account_confirmed)
      navigate('/confirm-phone');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <div className={`homepage ${auth?.token !== '' ? 'auth' : ''}`}>
      <img className='homebgimg' src={background} alt='background' />
      <Welcome />
      <Introbutons />
      {auth?.token === '' && (
        <p className='copyright'>
          Copyright © 2023 KoinPurse.com - All Rights Reserved.
        </p>
      )}
    </div>
  );
};

export default Home;
