import { Link, useNavigate } from 'react-router-dom';
import UnsubscribeBg from '../../assets/svg/unsubscribebg.svg';
import ArrowBack from '../../assets/svg/arrowback.svg';
import './Unsubscribe.scss';

interface Props {}

const Unsubscribe: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <div className='unsubscribe-container'>
      <div className='back-btn' onClick={() => navigate('/profile')}>
        <img src={ArrowBack} alt='' />
      </div>
      <div className='round-message-value'>
        <img className='bgmessage' src={UnsubscribeBg} alt='UpdateBg' />
      </div>
      <div className='unsubscribe-message-title'>Unsubscribe</div>
      <div className='unsubscribe-message-text'>
        Are you sure you want to unsubscribe? If you unsubscribe, there are no
        refunds and you can NOT resubscribe for 6(six) months.
      </div>
      <div className='btn-section'>
        <Link to='/profile' className='continue-btn'>
          Cancel
        </Link>
        <Link to='/unsubscribe-reasons' className='continue-btn'>
          Continue
        </Link>
      </div>
    </div>
  );
};

export default Unsubscribe;
