import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTransferUser,
  getUser,
  transferKoinsEmail,
} from "../../redux/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Warning from "../../containers/Warning";
import SubmitIcon from "../../assets/svg/ArrowRight.svg";
import CloseIcon from "../../assets/svg/notifications-close.svg";
import PlusIcon from "../../assets/svg/plusicon.svg";
import MinusIcon from "../../assets/svg/minusicon.svg";
import EmailKoin from "../../assets/svg/emailKoin.svg";
import "./SendKoins.scss";

interface Props {
  handleSendKoinsClose?: () => void;
}
interface SendKoin {
  email: string;
  koinvalue: number;
}
const SendKoins: React.FC<Props> = ({ handleSendKoinsClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { auth, transferUser } = useAppSelector((state: any) => state.auth);
  const [submit, setSubmit] = useState(false);

  const [formData, setFormData] = useState<SendKoin>({
    email: "",
    koinvalue: 0,
  });

  useEffect(() => {
    if (uuid) {
      dispatch(getTransferUser(uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    setFormData({ ...formData, email: transferUser?.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferUser]);

  const handleIncrement = () => {
    setFormData({
      ...formData,
      koinvalue: formData.koinvalue + 1,
    });
  };

  const handleDecrement = () => {
    if (formData.koinvalue > 0) {
      setFormData({
        ...formData,
        koinvalue: formData.koinvalue - 1,
      });
    }
  };

  const validate = (e: any) => {
    e.preventDefault();

    dispatch(
      transferKoinsEmail({
        email: formData.email,
        koins: formData.koinvalue,
        validate_only: true,
      })
    ).then((res) => {
      if (res?.payload?.status === "OK") {
        setSubmit(true);
      }
    });
    return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      transferKoinsEmail({
        email: formData.email,
        koins: formData.koinvalue,
      })
    ).then((res) => {
      if (res?.payload?.status === "OK") {
        setFormData({
          email: "",
          koinvalue: 0,
        });
        dispatch(getUser(auth.user.uuid));
        navigate("/koin-message/" + formData.koinvalue);
        setSubmit(false);
      }
    });
  };

  return (
    <>
      {!submit && (
        <div className="send-koins-container full-container">
          <div className="send-koins-header">
            <h1 className="title">Send Koins</h1>
            <div
              onClick={() => {
                handleSendKoinsClose
                  ? handleSendKoinsClose()
                  : navigate("/koin-purse");
              }}
              className="close"
            >
              <img src={CloseIcon} alt="notification icon" />
            </div>
          </div>
          <div className="send-koins-form">
            <form
              onSubmit={(e) => {
                validate(e);
              }}
            >
              <div className="input-wrapper-container">
                <div className="input-wrapper">
                  <div className="minus" onClick={handleDecrement}>
                    <img src={MinusIcon} alt="" />
                  </div>
                  <div className="value">
                    <input
                      type="number"
                      className="value"
                      value={formData.koinvalue}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          koinvalue: parseInt(e.target.value, 10),
                        })
                      }
                    />
                    <p className="label-value">Enter amount to transfer</p>
                  </div>
                  <div className="plus" onClick={handleIncrement}>
                    <img src={PlusIcon} alt="" />
                  </div>
                </div>
                <div className="line-trough"></div>
                <div className="input-email-container">
                  <div className="input-email-wrapper">
                    <img src={EmailKoin} alt="" />
                    <input
                      placeholder="Enter receiver email here"
                      type="text"
                      id="email"
                      disabled={typeof uuid === "string"}
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="btn-container">
                <div className="linear-gradient-button">
                  <button type="submit" className="submit-button">
                    Send
                    <img src={SubmitIcon} alt="" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {submit && (
        <Warning
          handleSubmit={(e) => handleSubmit(e)}
          handleBack={() => setSubmit(false)}
        />
      )}
    </>
  );
};

export default SendKoins;
