import { Route, Routes } from 'react-router-dom';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import Home from './containers/Home';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import NewPassword from './containers/NewPassword/NewPassword';
import KoinPurse from './containers/KoinPurse/KoinPurse';
import QrCode from './containers/QrCode/QrCode';
import KoinMessage from './containers/KoinMessage/KoinMessage';
import ConfirmEmail from './containers/ConfirmEmail';
import UserConfirmed from './containers/UserConfirmed';
import SendKoins from './components/SendKoins';
import Profile from './containers/Profile';
import Unsubscribe from './containers/Unsubscribe';
import UnsubscribeReasons from './containers/UnsubscribeReasons';
import PrivateRoutes from './PrivateRoutes';
import Notifications from './containers/Notifications';
import NotificationPage from './containers/NotificationPage';
import PasswordUpdated from './containers/PasswordUpdated';
import SuccessUnsubscribe from './containers/SuccessUnsubscribe';
import ChangePassword from './containers/ChangePassword';
import ProfileUpdated from './containers/ProfileUpdated';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Terms from './containers/Terms';
import ChangePaymentMethod from './containers/ChangePaymentMethod';
import ProfileEmailUpdated from './containers/ProfileEmailUpdated';
import ConfirmCode from './containers/ConfirmCode';
import ConfirmPhone from './containers/ConfirmPhone';

function Navigation() {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path='/profile' element={<Profile />} />
        <Route path='/profile-updated' element={<ProfileUpdated />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/koin-purse' element={<KoinPurse />} />
        <Route path='/qr-code' element={<QrCode />} />
        <Route path='/koin-message' element={<KoinMessage />} />

        <Route path='/unsubscribe-success' element={<SuccessUnsubscribe />} />
        <Route path='/koin-message/:nr' element={<KoinMessage />} />
        <Route path='/transfer-to-user/:uuid' element={<SendKoins />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/notification/:uuid' element={<NotificationPage />} />

        <Route path='/unsubscribe' element={<Unsubscribe />} />
        <Route path='/unsubscribe-reasons' element={<UnsubscribeReasons />} />
        <Route
          path='/change-payment-method'
          element={<ChangePaymentMethod />}
        />
      </Route>
      <Route path='/confirm-phone' element={<ConfirmPhone />} />

      <Route path='/privacy' element={<PrivacyPolicy />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/confirm-code' element={<ConfirmCode />} />
      <Route path='/email-confirmed' element={<UserConfirmed />} />
      <Route path='/password-updated' element={<PasswordUpdated />} />
      <Route path='/logout' element={<Home />} />
      <Route path='/confirm-email' element={<ConfirmEmail />} />
      <Route path='/user/confirm/:token' element={<ConfirmEmail />} />
      <Route path='/email/confirm/:token' element={<ConfirmEmail />} />
      <Route path='/profile-email-updated' element={<ProfileEmailUpdated />} />

      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password/:token' element={<NewPassword />} />
    </Routes>
  );
}

export default Navigation;
