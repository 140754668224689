import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from './redux/hooks';

const PrivateRoutes = () => {
  const { auth } = useAppSelector((state: any) => state.auth);
  return auth.token !== '' ? (
    auth?.user?.is_account_confirmed ? (
      <Outlet />
    ) : (
      <Navigate to='/confirm-phone' />
    )
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoutes;
