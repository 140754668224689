import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotification } from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import MessageBg from '../../assets/svg/messagebg.svg';
import ArrowBack from '../../assets/svg/arrowback.svg';
import './NotificationPage.scss';

interface Props {}

const NotificationPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const { notification } = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(getNotification(uuid ?? ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='notification-message-container add-notification'>
      <div className='back-btn' onClick={() => navigate(-1)}>
        <img src={ArrowBack} alt='' />
      </div>
      {notification && (
        <>
          <div
            className={`round-message-value ${
              notification?.meta?.type === 'send' && 'deducted'
            }`}
          >
            <img className='bgmessage' src={MessageBg} alt='QrCodeFull' />
            <span
              className={`${
                notification?.meta?.current_user_full_name
                  ? 'bigger'
                  : 'smaller'
              }`}
            >
              {notification?.meta?.type === 'receive'
                ? '+' + notification.meta?.amount
                : notification?.meta?.type === 'send'
                ? '-' + notification.meta?.amount
                : ''}
            </span>
          </div>
          <div className='notification-message-text'>
            {notification?.meta?.type === 'receive'
              ? notification?.message
              : `You sent ${notification.meta?.amount} ${
                  notification?.meta?.amount > 1 ? 'koins' : 'koin'
                } to ${notification.meta?.user_to_transfer_full_name}`}
          </div>
        </>
      )}
      <div className='btn-section'>
        <button onClick={() => navigate(-1)} className='continue-btn'>
          Continue
        </button>
      </div>
    </div>
  );
};

export default NotificationPage;
