import { Link } from "react-router-dom";
import "./Welcome.scss";

const Welcome = () => {
  return (
    <div className="welcome">
      <h1>Welcome Back!</h1>
      <p>For More Info, Go To KommunityKoin.com or KoinPurse.info</p>
      <div className="btns-container">
        <Link to="https://kommunitykoin.com/" target="_blank">
          KommunityKoin
        </Link>
        <Link to="https://koinpurse.info/" target="_blank">
          User Guide
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
