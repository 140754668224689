import React, { useState, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { authentication, getUser } from '../../redux/auth/authThunk';
import { AuthenticationProps } from '../../models/Auth';
import Envelope from '../../assets/svg/EnvelopeOpen.svg';
import Lock from '../../assets/svg/Lock.svg';
import SubmitIcon from '../../assets/svg/ArrowUpRight.svg';
import background from '../../assets/img/loginbg.png';
import './Login.scss';
import { hasError } from '../../assets/helpers/helpers';

interface Props {}

const Login: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);

  const [formData, setFormData] = useState<AuthenticationProps>({
    email: '',
    password: ''
  });
  const { auth, error, firstLogin } = useAppSelector(
    (state: any) => state.auth
  );

  useEffect(() => {
    if (auth?.token !== '') {
      firstLogin ? navigate('/koin-purse') : navigate('/');
      setFormData({
        email: '',
        password: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(authentication(formData)).then(res => {
      res?.payload?.uuid &&
        dispatch(
          getUser({ uuid: res?.payload?.uuid, token: res?.payload?.token })
        );

      setErrors(typeof error === 'string' ? [error] : error);
    });
  };

  return (
    <div className='login-container'>
      <img className='loginbgimg' src={background} alt='background' />
      <div className='form-conatiner'>
        <h4 className='title'>Login</h4>
        <form onSubmit={handleSubmit}>
          <div
            className={`inputs-field ${
              hasError(errors, 'email') ? 'error' : ''
            }`}
          >
            <img src={Envelope} alt='' />
            <input
              placeholder='Email Address'
              type='text'
              id='email'
              value={formData.email}
              onChange={e => {
                setFormData({
                  ...formData,
                  email: e.target.value.toLocaleLowerCase()
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'email')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'password') ? 'error' : ''
            }`}
          >
            <img src={Lock} alt='' />
            <input
              autoComplete=''
              placeholder='Password'
              type='password'
              id='password'
              value={formData.password}
              onChange={e => {
                setFormData({
                  ...formData,
                  password: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'password')
                );
              }}
            />
          </div>
          <div className='linear-gradient-button'>
            <button type='submit' className='submit-button'>
              Sign In
              <img src={SubmitIcon} alt='' />
            </button>
          </div>
        </form>
        <Link className='forgot-password' to='/forgot-password'>
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
