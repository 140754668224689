import { toast } from 'react-toastify';
import ErrorIcon from '../../assets/svg/error.svg';
import SuccessIcon from '../../assets/svg/success.png';

export const notify = (text: string) => {
  toast.error(
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      <img src={ErrorIcon} alt='' />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '4px'
        }}
      >
        <span style={{ fontSize: '18px', lineHeight: '26px', color: '#fff' }}>
          Error
        </span>
        <span style={{ color: '#777', fontSize: '16px' }}>{text}</span>
      </div>
    </div>,
    {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'dark'
    }
  );
};

export const notifySuccess = (text: string) => {
  toast.success(
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      {' '}
      <img src={SuccessIcon} alt='' />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '4px'
        }}
      >
        <span style={{ fontSize: '18px', lineHeight: '26px', color: '#fff' }}>
          Success
        </span>
        <span style={{ color: '#777', fontSize: '16px' }}>{text}</span>
      </div>
    </div>,
    {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'dark'
    }
  );
};

export const hasError = (error: any[], text: string): boolean => {
  return error?.filter((err: any) => err.key === text).length > 0;
};
