import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store";
import "./assets/styles/global.scss";
import "./styles/App.scss";
import Layout from "./Layout";

function App() {
  return (
    <div>
      <Provider store={store}>
        <Layout />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          theme="dark"
        />
      </Provider>
    </div>
  );
}

export default App;
