import { createAsyncThunk } from "@reduxjs/toolkit";

import { appAxios } from "../axiosInstance";
import {
  AuthenticationProps,
  ChangePasswordT,
  CheckSms,
  EditUserT,
  ForgotPasswordProps,
  RegisterProps,
  ResetPasswordProps,
  SendConfirmationCode,
  TokenProps,
} from "../../models/Auth";
import { notify } from "../../assets/helpers/helpers";
import { TransferKoinsEmail } from "../../models/Transfer";
import { Card } from "../../models/Models";

export const authentication = createAsyncThunk<any, AuthenticationProps>(
  "auth/fetchAccess",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/login", data);

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const checkSMS = createAsyncThunk<any, CheckSms>(
  "auth/checkSMS",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/check-sms-confirmation-code ",
        data
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors.message === "string"
        ? notify(error.response.data.errors.message)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const sendConfirmationCode = createAsyncThunk<any, SendConfirmationCode>(
  "auth/sendConfirmationCode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/send-confirmation-code ", data);

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk<any, { uuid: string; token: string }>(
  "auth/getUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        "api/secured/user/" +
          (localStorage.getItem("uuid")
            ? JSON.parse(localStorage.getItem("uuid") ?? "")
            : ""),
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const editUser = createAsyncThunk<any, EditUserT>(
  "auth/editUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.patch("api/secured/user/", data, {
        headers: {
          "X-Access-Token": localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token") ?? "")
            : "",
        },
      });

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const clearTemporaryEmail = createAsyncThunk<any>(
  "auth/editUser",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/user/clear-temporary-email",
        {},
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk<any, ChangePasswordT>(
  "auth/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/change-password",
        data,
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk<any, ForgotPasswordProps>(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/forgot-password", data);
      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );

      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk<any, ResetPasswordProps>(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/reset-password/" + data.uuid, {
        password: data.password,
        confirm_password: data.confirm_password,
      });
      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const register = createAsyncThunk<any, RegisterProps>(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/user", data);
      const auth = { ...response.data };

      return auth;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const confirmUser = createAsyncThunk<TokenProps, string>(
  "auth/confirmUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post("api/confirm-token", {
        token: data,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

// koin-purse

export const transferKoinsEmail = createAsyncThunk<any, TransferKoinsEmail>(
  "auth/transferKoinsEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/user/transfer-to-email",
        data,
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const getTransferUser = createAsyncThunk<any, string>(
  "auth/getTransferUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get("api/secured/user/" + data, {
        headers: {
          "X-Access-Token": localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token") ?? "")
            : "",
        },
      });

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);
//subscribe - unsubscribe

export const subscribe = createAsyncThunk<any, any>(
  "auth/subscribe",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/user/subscribe",
        {
          source_id: data?.source_id || "",
          card_number: data?.card_number || "",
          card_type: data?.card_type || "",
        },
        {
          headers: {
            "X-Access-Token": data?.accessToken || "",
          },
        }
      );

      const savedUser = JSON.parse(localStorage.getItem("user") || "");
      savedUser.card_number = data?.card_number;
      savedUser.card_type = data?.card_type;
      localStorage.setItem("user", JSON.stringify(savedUser));

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const unsubscribe = createAsyncThunk<
  any,
  { token: string; reason: string }
>("auth/unsubscribe", async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.post(
      "api/secured/user/unsubscribe",
      { reason: data.reason },
      {
        headers: {
          "X-Access-Token": data.token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    typeof error.response.data.errors === "string"
      ? notify(error.response.data.errors)
      : error.response.data.errors.forEach((errorMessage: any) =>
          notify(errorMessage.message)
        );
    return rejectWithValue(error);
  }
});

// notifications
export const getNotifications = createAsyncThunk<any, any>(
  "auth/getNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get("api/secured/notification", {
        params: {
          ...data,
          order_by: "createdAt",
          order: "desc",
          message_key: "notifications._user_send_coins",
        },
        headers: {
          "X-Access-Token": localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token") ?? "")
            : "",
        },
      });

      return { ...response.data, ...data };
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const getNotification = createAsyncThunk<any, string>(
  "auth/getNotification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get("api/secured/notification/" + data, {
        headers: {
          "X-Access-Token": localStorage.getItem("token")
            ? JSON.parse(localStorage.getItem("token") ?? "")
            : "",
        },
      });

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const updateUserKoins = createAsyncThunk<any, any>(
  "auth/updateUserKoins",
  async (data, { rejectWithValue }) => {
    try {
      const savedUser = JSON.parse(localStorage.getItem("user") || "");
      savedUser.koins = data;
      localStorage.setItem("user", JSON.stringify(savedUser));

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const clearNotifications = createAsyncThunk<any, any>(
  "auth/clearNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/notification/bulk-delete",
        data,
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);

export const updatePaymentMethod = createAsyncThunk<any, Card>(
  "auth/updatePaymentMethod",
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        "api/secured/user/change-card-data",
        data,
        {
          headers: {
            "X-Access-Token": localStorage.getItem("token")
              ? JSON.parse(localStorage.getItem("token") ?? "")
              : "",
          },
        }
      );

      const savedUser = JSON.parse(localStorage.getItem("user") || "");
      savedUser.card_number = data?.card_number;
      savedUser.card_type = data?.card_type;
      localStorage.setItem("user", JSON.stringify(savedUser));

      return response.data;
    } catch (error: any) {
      typeof error.response.data.errors === "string"
        ? notify(error.response.data.errors)
        : error.response.data.errors.forEach((errorMessage: any) =>
            notify(errorMessage.message)
          );
      return rejectWithValue(error);
    }
  }
);
