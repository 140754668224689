import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/auth-slice';

export const store = configureStore({
  reducer: {
    auth: authSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
