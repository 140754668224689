import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, unsubscribe } from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ArrowBack from '../../assets/svg/arrowback.svg';
import './UnsubscribeReasons.scss';

interface Props {}

const UnsubscribeReasons: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state: any) => state.auth);
  const [reason, setReason] = useState('Didn’t meet my expectations');
  const [textareaValue, setTextareaValue] = useState('');

  const handleUnsubscribe = (e: any) => {
    e.preventDefault();

    dispatch(
      unsubscribe({
        token: auth?.token,
        reason: reason === 'Other' ? textareaValue : reason
      })
    ).then((res: any) => {
      if (res.payload && typeof res.payload !== 'string') {
        dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token }));
        navigate('/unsubscribe-success');
      }
    });
  };

  return (
    <>
      <div className='unsubscribe-reasons-container'>
        <div className='back-btn' onClick={() => navigate('/unsubscribe')}>
          <img src={ArrowBack} alt='' />
        </div>
        <div className='unsubscribe-message-top'>
          <div className='unsubscribe-message-title'>Unsubscribe</div>
          <div className='unsubscribe-message-text'>
            Your Email Address:
            <br />
            {auth?.user?.email}
          </div>
        </div>
        <div className='unsubscribe-message-separator'></div>
        <div className='unsubscribe-message-bottom'>
          <div className='unsubscribe-message-title'>Reasons:</div>
          <div className='unsubscribe-message__radio'>
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Didn’t meet my expectations')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Didn’t meet my expectations'}
                className={` ${
                  reason === 'Didn’t meet my expectations' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Didn’t meet my expectations</span>
            </div>
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Didn’t meet my needs')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Didn’t meet my needs'}
                className={` ${
                  reason === 'Didn’t meet my needs' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Didn’t meet my needs</span>
            </div>
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Too expensive')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Too expensive'}
                className={` ${reason === 'Too expensive' ? 'checked' : ''}`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Too expensive</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Not really using it')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Not really using it'}
                className={` ${
                  reason === 'Not really using it' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Not really using it</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason("Didn't like the functionality")}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === "Didn't like the functionality"}
                className={` ${
                  reason === "Didn't like the functionality" ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Didn't like the functionality</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Didn’t like the design')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Didn’t like the design'}
                className={` ${
                  reason === 'Didn’t like the design' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Didn’t like the design</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Subscribed by mistake')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Subscribed by mistake'}
                className={` ${
                  reason === 'Subscribed by mistake' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Subscribed by mistake</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Would rather not say')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Would rather not say'}
                className={` ${
                  reason === 'Would rather not say' ? 'checked' : ''
                }`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Would rather not say</span>
            </div>{' '}
            <div
              className='unsubscribe-message__option'
              onClick={() => setReason('Other')}
            >
              <input
                type='checkbox'
                name='reason'
                readOnly
                checked={reason === 'Other'}
                className={` ${reason === 'Other' ? 'checked' : ''}`}
              />
              <div className='unsubscribe-message__label'></div>
              <span>Other</span>
            </div>
            {reason === 'Other' && (
              <div className='other-textarea'>
                <div className='wrapper'>
                  <textarea
                    rows={1}
                    placeholder='Tell us why? (optional)'
                    value={textareaValue}
                    onChange={e => setTextareaValue(e.target.value)}
                  ></textarea>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='btn-section'>
          <div className='continue-btn' onClick={e => handleUnsubscribe(e)}>
            Unsubscribe
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default UnsubscribeReasons;
