import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import Header from './components/Header';
import BottomNavigation from './components/BottomNavigation';
import Navigation from './Navigation';
import './assets/styles/global.scss';
import './styles/App.scss';
import { getUser, updateUserKoins } from './redux/auth/authThunk';

interface Props {}

const Layout: React.FC<Props> = () => {
  const { auth } = useAppSelector((state: any) => state.auth);

  const [notificationsCount, setNotificationsCount] = useState(0);
  const [socketURL, setSocketURL] = useState(
    process.env.REACT_APP_WEB_SOCKET_URL +
      `${
        localStorage.getItem('uuid')
          ? JSON.parse(localStorage.getItem('uuid') || '')
          : ''
      }` || ''
  );
  const [isSockedConnected, setIsSockedConnected] = useState(false);
  const { lastJsonMessage }: any = useWebSocket(socketURL);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('notificationsCount')) {
      setNotificationsCount(
        JSON.parse(localStorage.getItem('notificationsCount') || '')
      );
    }
    dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage?.eventName === 'transfer') {
      localStorage.setItem(
        'notificationsCount',
        JSON.stringify(notificationsCount + 1)
      );

      dispatch(updateUserKoins(lastJsonMessage.data.koins_amount));

      setNotificationsCount(prevValue => {
        return prevValue + 1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  useEffect(() => {
    if (auth.uuid && !isSockedConnected) {
      setSocketURL(process.env.REACT_APP_WEB_SOCKET_URL + auth.uuid);
      setIsSockedConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <BrowserRouter>
      <Header
        notificationsCount={notificationsCount}
        setNotificationsCount={count => setNotificationsCount(count)}
      />
      <div className='App'>
        <Navigation />
      </div>
      {auth?.token !== '' && auth?.user?.is_account_confirmed && (
        <BottomNavigation subscribed={auth?.user?.is_subscribed} />
      )}
    </BrowserRouter>
  );
};

export default Layout;
