import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import logo from '../../assets/img/koinLogo.png';
import Iconlogin from '../../assets/svg/iconLogin.svg';
import Bell from '../../assets/svg/BellSimple.svg';
import ArrowDown from '../../assets/svg/ArrowDownRight.svg';
import './Header.scss';

export interface HeaderProps {
  notificationsCount: number;
  setNotificationsCount: (count: number) => void;
}

const Header: React.FC<HeaderProps> = ({
  notificationsCount,
  setNotificationsCount
}) => {
  const location = useLocation();
  const { auth } = useAppSelector(state => state.auth);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 80) setIsScrolled(true);
      else setIsScrolled(false);
    });

    return document.removeEventListener('scroll', () => {});
  }, []);

  const clearNotifications = () => {
    localStorage.removeItem('notificationsCount');
    setNotificationsCount(0);
  };

  return (
    <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className='header-container'>
        <Link to='/' className='logo-conatiner'>
          <img src={logo} alt='logo' />
        </Link>
        <div className='btn-container'>
          {location.pathname !== '/login' &&
            location.pathname !== '/confirm-email' &&
            auth?.token === '' && (
              <Link to='/login' className='loginbtn'>
                Login
                <img src={Iconlogin} alt='login icon' />
              </Link>
            )}
          {location.pathname === '/login' && auth?.token === '' && (
            <Link to='/sign-up' className='loginbtn'>
              Sign Up
              <img src={Iconlogin} alt='sign up icon' />
            </Link>
          )}
          {auth?.token !== '' && auth?.user?.is_account_confirmed && (
            <>
              <Link
                to='/logout'
                className='authbtn'
                onClick={() => clearNotifications()}
              >
                <img src={ArrowDown} alt='sign up icon' />
              </Link>
              <Link
                to='/notifications'
                className='authbtn'
                onClick={() => clearNotifications()}
              >
                {notificationsCount > 0 && (
                  <span className='badge'>{notificationsCount}</span>
                )}

                <img src={Bell} alt='sign up icon' />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
