import { Link, useParams } from 'react-router-dom';
import MessageBg from '../../assets/svg/messagebg.svg';
import './KoinMessage.scss';

interface Props {}

const KoinMessage: React.FC<Props> = () => {
  const { nr } = useParams();

  return (
    <>
      {/* Set add-koin class when the koins is added and remove add-koin class when koins is deducted */}
      <div className={`koin-message-container ${nr ? '' : 'add-koin'}`}>
        <div className='round-message-value'>
          <img className='bgmessage' src={MessageBg} alt='QrCodeFull' />
          <span>
            {nr ? '-' + nr : `+${process.env.REACT_APP_KOINS_SUBSCRIBE}`}
          </span>
        </div>
        <div className='koin-message-text'>
          {nr
            ? 'Koins Deducted from Your Koin Purse!'
            : `You have successfully subscribed and you now have ${process.env.REACT_APP_KOINS_SUBSCRIBE} Koins.`}
        </div>
        <div className='btn-section'>
          <Link to='/koin-purse' className='continue-btn'>
            Continue
          </Link>
        </div>
      </div>
    </>
  );
};

export default KoinMessage;
