import React, { useState, FormEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetPassword } from '../../redux/auth/authThunk';
import background from '../../assets/svg/forgotbg.svg';
import Lock from '../../assets/svg/Lock.svg';
import ShieldCevron from '../../assets/svg/ShieldChevron.svg';
import '../ForgotPassword/RessetPassword.scss';
import { hasError } from '../../assets/helpers/helpers';

interface Props {}
interface ForgotPassForm {
  newPassword: string;
  confirmPassword: string;
}

const NewPassword: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token = '' } = useParams();
  const [formData, setFormData] = useState<ForgotPassForm>({
    newPassword: '',
    confirmPassword: ''
  });
  const { error } = useAppSelector((state: any) => state.auth);
  const [errors, setErrors] = useState([]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      resetPassword({
        uuid: token,
        password: formData.newPassword,
        confirm_password: formData.confirmPassword
      })
    ).then((res: any) => {
      setErrors(typeof error === 'string' ? [error] : error);

      if (typeof res.payload !== 'string' && res.payload?.status === 'OK') {
        navigate('/password-updated');
        setFormData({
          newPassword: '',
          confirmPassword: ''
        });
      }
    });
  };

  return (
    <div className='resset-pass-container'>
      <img className='forgotbgimg' src={background} alt='background' />
      <div className='form-conatiner'>
        <h4 className='title'>Reset password</h4>

        <form onSubmit={handleSubmit}>
          <div
            className={`inputs-field ${
              hasError(errors, 'password') ? 'error' : ''
            }`}
          >
            <img src={Lock} alt='' />
            <input
              autoComplete=''
              placeholder='New Password'
              type='password'
              id='password'
              value={formData.newPassword}
              onChange={e => {
                setFormData({
                  ...formData,
                  newPassword: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'password')
                );
              }}
            />
          </div>
          <div
            className={`inputs-field ${
              hasError(errors, 'confirm_password') ? 'error' : ''
            }`}
          >
            <img src={ShieldCevron} alt='' />
            <input
              autoComplete=''
              placeholder='Confirm Password'
              type='password'
              id='confirmpassword'
              value={formData.confirmPassword}
              onChange={e => {
                setFormData({
                  ...formData,
                  confirmPassword: e.target.value
                });
                setErrors(prev =>
                  prev?.filter((err: any) => err.key !== 'confirm_password')
                );
              }}
            />
          </div>
          <div className='linear-gradient-button'>
            <button type='submit' className='submit-button'>
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
