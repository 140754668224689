import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/svg/notifications-close.svg";
import NotificationsIcon from "../../assets/img/notifications-icon.png";
import "./Notifications.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import {
  getNotifications,
  clearNotifications,
} from "../../redux/auth/authThunk";

interface Props {}

const Notifications: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(getNotifications({}));

    if (localStorage.getItem("notificationsCount")) {
      const notificationsCount = JSON.parse(
        localStorage.getItem("notificationsCount") || ""
      );

      if (notificationsCount > 0) {
        dispatch(getNotifications({ is_new: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClear = () => {
    if (notifications.length > 0) {
      const notificationsToClear: any[] = [];

      notifications.forEach((notification: any) => {
        notificationsToClear.push(notification.uuid);
      });

      dispatch(clearNotifications({ uuids: notificationsToClear }));
    }
  };

  return (
    <div className="notifications-container full-container">
      <div className="notifications-header">
        <div className="clear" onClick={handleClear}>
          Clear all
        </div>
        <div className="title">
          <img src={NotificationsIcon} alt="notification icon" />
          <span>Notifications</span>
        </div>
        <div onClick={() => navigate(-1)} className="close">
          <img src={CloseIcon} alt="notification icon" />
        </div>
      </div>
      <div className="notifications-list">
        {notifications?.map((notification: any, notificationIndex: number) => {
          return (
            <div
              className="notifications-item"
              key={notificationIndex}
              onClick={() => navigate("/notification/" + notification?.uuid)}
            >
              <div className="notifications-item__email">
                {notification?.meta?.email ?? notification?.meta?.email}
              </div>
              {notification?.meta?.amount && (
                <div
                  className={`notifications-item__value ${
                    notification?.meta?.current_user_full_name
                      ? "bigger"
                      : "smaller"
                  }`}
                >
                  <span>
                    {notification?.meta?.type === "send"
                      ? "-" + notification.meta?.amount
                      : "+" + notification.meta?.amount}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
