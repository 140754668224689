import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import "./PayForm.scss";
import { Card } from "../../models/Models";

const PayForm = ({ setPaymentToken, isUpdate = false }: any) => {
  return (
    <div className={`payment-form ${isUpdate ? "update-payment-method" : ""}`}>
      <div className="payment-form-content">
        <PaymentForm
          applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID || ""}
          cardTokenizeResponseReceived={(res: any) => {
            if (res.token) {
              const cardDetails: Card = {
                source_id: res.token,
                card_number: res.details.card.last4,
                card_type: res.details.card.brand,
              };

              setPaymentToken(cardDetails);
            }
          }}
          locationId={process.env.REACT_APP_SQUARE_LOCATION_ID || ""}
        >
          <CreditCard />
        </PaymentForm>
      </div>
    </div>
  );
};

export default PayForm;
