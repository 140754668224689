import EmailUpdateAccountMessage from "../../components/EmailUpdateAccountMessage";
import PasswordBg from "../../assets/img/passwordbg.png";
import "./ProfileUpdated.scss";

interface Props {}

const ProfileUpdated: React.FC<Props> = () => {
  return (
    <EmailUpdateAccountMessage
      title={"You have successfully updated your account."}
      link="/profile"
      img={PasswordBg}
    />
  );
};

export default ProfileUpdated;
