import { Link } from "react-router-dom";
import UpdateBg from "../../assets/svg/updatedaccountbg.svg";
import "./EmailUpdateAccountMessage.scss";

interface Props {
  title: string;
  desc?: string;
  link?: string;
  img?: any;
}

const EmailUpdateAccountMessage: React.FC<Props> = ({
  title,
  link,
  desc,
  img,
}: Props) => {
  return (
    <>
      <div className="update-message-container">
        <div className="round-message-value">
          <img className="bgmessage" src={img ?? UpdateBg} alt="UpdateBg" />
        </div>
        <div className="koin-message-title">{title}</div>
        {desc && <div className="koin-message-text">{desc}</div>}
        <div className="btn-section">
          <Link to={link ?? "/login"} className="continue-btn">
            Continue
          </Link>
        </div>
      </div>
    </>
  );
};

export default EmailUpdateAccountMessage;
