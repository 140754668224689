import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { UserT } from '../KoinPurse/KoinPurse';
import './QrCode.scss';

interface Props {}

const QrCode: React.FC<Props> = () => {
  const { auth } = useAppSelector((state: any) => state.auth);
  const [user, setUser] = useState<UserT | null>(null);

  useEffect(() => {
    if (auth?.user) {
      setUser(auth?.user);
    }
  }, [auth]);

  return (
    <>
      {user && (
        <div className='qrcode-container full-container'>
          <h1 className='title'>QR-Code</h1>
          <img className='qr-code' src={user?.qr} alt='QrCodeFull' />
        </div>
      )}
    </>
  );
};

export default QrCode;
