import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Homeicon from '../../assets/svg/HouseLine.svg';
import Money from '../../assets/svg/Money.svg';
import QrCodeIcon from '../../assets/svg/qrcodeicon.svg';
import ProfileIcon from '../../assets/svg/profile.svg';
import HomeiconActive from '../../assets/svg/HomeiconActive.svg';
import MoneyActive from '../../assets/svg/MoneyActive.svg';
import QrCodeIconActive from '../../assets/svg/qrcodeactive.svg';
import ProfileIconActive from '../../assets/svg/profileactive.svg';
import './BottomNavigation.scss';

interface Props {
  subscribed: boolean;
}

const BottomNavigation = ({ subscribed }: Props) => {
  const location = useLocation();

  return (
    !location.pathname.includes('koin-message') &&
    !location.pathname.includes('unsubscribe') &&
    !location.pathname.includes('profile-updated') && (
      <div
        className={`nav-menu__navigation ${!subscribed ? 'spacing' : ''} ${
          location.pathname === '/qr-code' ? 'gray-bg' : ''
        }`}
      >
        <Link
          className={`nav-item ${
            location.pathname === '/' || location.pathname === '/home'
              ? 'active'
              : ''
          }`}
          to='/'
        >
          <div className='img-block'>
            <img src={Homeicon} alt='home' />
            <img className='active-img' src={HomeiconActive} alt='home' />
          </div>
          Home
        </Link>
        <Link
          className={`nav-item ${
            location.pathname === '/koin-purse' ? 'active' : ''
          }`}
          to='/koin-purse'
        >
          <div className='img-block'>
            <img src={Money} alt='money' />
            <img className='active-img' src={MoneyActive} alt='money' />
          </div>
          Koin Purse
        </Link>
        {subscribed && (
          <Link
            className={`nav-item ${
              location.pathname === '/qr-code' ? 'active' : ''
            }`}
            to='/qr-code'
          >
            <div className='img-block'>
              <img src={QrCodeIcon} alt='qrcode' />
              <img className='active-img' src={QrCodeIconActive} alt='qrcode' />
            </div>
            QR-Code
          </Link>
        )}
        <Link
          className={`nav-item ${
            location.pathname === '/profile' ? 'active' : ''
          }`}
          to='/profile'
        >
          <div className='img-block'>
            <img src={ProfileIcon} alt='profile' />
            <img className='active-img' src={ProfileIconActive} alt='profile' />
          </div>
          Profile
        </Link>
      </div>
    )
  );
};

export default BottomNavigation;
