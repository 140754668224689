import AccountMessage from '../../components/AccountMessage';
import PasswordBg from '../../assets/img/passwordbg.png';
import './PasswordUpdated.scss';

interface Props {}

const PasswordUpdated: React.FC<Props> = () => {
  return (
    <AccountMessage
      title={'You have successfully changed your password.'}
      link='/login'
      img={PasswordBg}
    />
  );
};

export default PasswordUpdated;
