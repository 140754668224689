import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ArrowBack from "../../assets/svg/arrowback.svg";
import PayForm from "../../components/PaymentForm/PaymentForm";
import { Card } from "../../models/Models";
import { getUser, updatePaymentMethod } from "../../redux/auth/authThunk";

const ChangePaymentMethod = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { auth } = useAppSelector((state) => state.auth);

  const updateCard = (card: Card) => {
    dispatch(updatePaymentMethod(card)).then((res: any) => {
      if (res.payload?.status === "OK") {
        dispatch(getUser(auth.user.uuid)).then(() => {
          navigate("/profile");
        });
      }
    });
  };

  return (
    <div className="change-payment-method">
      <div className="back-btn" onClick={() => navigate("/profile")}>
        <img src={ArrowBack} alt="" />
      </div>
      <div className="change-password-top">
        <div className="change-password-title">Change Payment Method</div>
      </div>

      <PayForm setPaymentToken={updateCard} isUpdate={true} />
    </div>
  );
};

export default ChangePaymentMethod;
