import React from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import QrReader from 'react-qr-scanner';
import CloseIcon from '../../assets/svg/notifications-close.svg';
import './QrReader.scss';

interface Props {
  handleScanClose?: () => void;
}

const QrReaderComponent: React.FC<Props> = ({ handleScanClose }) => {
  const navigate = useNavigate();

  const handleScan = (e: any) => {
    if (e?.text) {
      navigate('/transfer-to-user/' + e?.text.split('/').pop());
    }
  };

  return (
    <>
      <div className='send-koins-container full-container'>
        <div className='send-koins-header'>
          <h1 className='title'>Scan QR Code</h1>
          {handleScanClose && (
            <div onClick={handleScanClose} className='close'>
              <img src={CloseIcon} alt='notification icon' />
            </div>
          )}
        </div>
        <div className='send-koins-form'>
          <QrReader
            key='environment'
            delay={100}
            constraints={{ audio: false, video: { facingMode: 'environment' } }}
            style={{}}
            onError={() => {}}
            onScan={(e: any) => handleScan(e)}
          />
        </div>
      </div>
    </>
  );
};

export default QrReaderComponent;
