import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { confirmUser, logout } from "../../redux/auth/authThunk";
import background from "../../assets/img/confirmemailbg.png";
import "./ConfirmEmail.scss";
import { notify } from "../../assets/helpers/helpers";

interface Props {}

const ConfirmEmail: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (location.pathname.includes("user/confirm")) {
      token &&
        dispatch(confirmUser(token)).then((res: any) => {
          if (res.payload?.status === "OK") {
            dispatch(logout());
            navigate("/email-confirmed");
          } else {
            notify(res?.payload?.response?.data?.errors[0].message);
            navigate("/login");
          }
        });
    }
    if (location.pathname.includes("email/confirm")) {
      token &&
        dispatch(confirmUser(token)).then((res: any) => {
          if (res.payload?.status === "OK") {
            dispatch(logout());
            navigate("/profile-email-updated");
          } else {
            notify(res?.payload?.response?.data?.errors[0].message);
            navigate("/login");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {!token && (
        <div className="confirm-email-container">
          <img className="confirm-bg" src={background} alt="background" />
          <div className="confirm-email-title">Confirm Email</div>
          <div className="confirm-email-description">
            Confirm your account changes by clicking on the link provided in
            your email.
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmEmail;
