import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.scss';
import ArrowBack from '../../assets/svg/arrowback.svg';

interface Props {}

const PrivacyPolicy: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <div className={`privacy-policy-page`}>
      <div className='back-btn' onClick={() => navigate(-1)}>
        <img src={ArrowBack} alt='' />
      </div>
      <h1 className='title'>ONLINE PRIVACY POLICY AGREEMENT</h1>

      <p className='date'>December 14, 2023</p>

      <p className='pl-5'>
        KoinPurse.com ( KoinPurse) values its users' privacy. This Privacy
        Policy ("Policy") will help you understand how we collect and use
        personal information from those who visit our website or make use of our
        online facilities and services, and what we will and will not do with
        the information we collect. Our Policy has been designed and created to
        assure those affiliated with KoinPurse.com of our commitment and
        realization of our obligation not only to meet, but to exceed, most
        existing privacy standards.
      </p>

      <p className='pl-5'>
        We reserve the right to make changes to this Policy at any given time.
        If you have not registered with us and want to make sure that you are up
        to date with the latest changes, we advise you to frequently visit this
        page. If you are a registered user, we will notify you via email of any
        changes or updates made to this Policy. If at any point in time
        KoinPurse.com decides to make use of any personally identifiable
        information on file, in a manner vastly different from that which was
        stated when this information was initially collected, the user or users
        shall be promptly notified by email. Users at that time shall have the
        option as to whether to permit the use of their information in this
        separate manner.
      </p>

      <p className='pl-5'>
        This Policy applies to KoinPurse.com and any subsidiary company listed
        below, and it governs any and all data collection and usage by us.
        Through the use of KoinPurse.com and any subsidiary website listed
        below, you are therefore consenting to the data collection procedures
        expressed in this Policy.
      </p>
      <div className=' p-intern'>
        <p className={'a-center p-wrapp '}>
          <span className='s-1'>Subsidiary Company:</span>
          <span> KommunityKoin.com</span>
        </p>
        <p className={'a-center p-wrapp'}>
          <span className='s-1'>Subsidiary Website:</span>

          <span> KommunityKoin.com</span>
        </p>
      </div>
      <p className='justify'>
        Please note that this Policy does not govern the collection and use of
        information by companies that KoinPurse.com does not control, nor by
        individuals not employed or managed by us. If you visit a website that
        we mention or link to, be sure to review its privacy policy before
        providing the site with information. It is highly recommended and
        suggested that you review the privacy policies and conditions of any
        website you choose to use or frequent to better understand the way in
        which websites garner, make use of and share the information collected.
      </p>

      <p className='pl-5-left'>
        Specifically, this Policy will inform you of the following
      </p>
      <ol id='l1'>
        <li data-list-text={1}>
          <p className='pt-5 li'>
            What personally identifiable information is collected from you
            through our website;
          </p>
        </li>
        <li data-list-text={2}>
          <p className='li'>
            Why we collect personally identifiable information and the legal
            basis for such collection;
          </p>
        </li>
        <li data-list-text={3}>
          <p className='li'>
            How we use the collected information and with whom it may be shared;
          </p>
        </li>
        <li data-list-text={4}>
          <p className='li'>
            What choices are available to you regarding the use of your data;
            and
          </p>
        </li>
        <li data-list-text={5}>
          <p className='li'>
            The security procedures in place to protect the misuse of your
            information.
          </p>
        </li>
      </ol>

      <h2 className='pl-5-left'>Information We Collect</h2>

      <p className='pt-5 pl-5'>
        It is always up to you whether to disclose personally identifiable
        information to us, although if you elect not to do so, we reserve the
        right not to register you as a user or provide you with any products or
        services. This website collects various types of information, such as:
      </p>
      <ul id='l2'>
        <li data-list-text='●'>
          <p className='s2 unordered-li'>
            <span className='p'>
              Voluntarily provided information which may include your name,
              address, email address, billing and/or credit card information
              etc. which may be used when you purchase products and/or services
              and to deliver the services you have requested.
            </span>
          </p>
        </li>
        <li data-list-text='●'>
          <p className='s2 unordered-li'>
            <span className='p'>
              Information automatically collected when visiting our website,
              which may include cookies, third party tracking technologies and
              server logs.
            </span>
          </p>
        </li>
      </ul>
      <p className='pl-5'>
        In addition, KoinPurse.com may have the occasion to collect non-personal
        anonymous demographic information, such as age, gender, household
        income, political affiliation, race and religion, as well as the type of
        browser you are using, IP address, or type of operating system, which
        will assist us in providing and maintaining superior quality service.
      </p>
      <p className='pl-5 pt-5'>
        KoinPurse.com may also deem it necessary, from time to time, to follow
        websites that our users may frequent to gleam what types of services and
        products may be the most popular to customers or the general public.
      </p>

      <p className='pl-5'>
        Please rest assured that this site will only collect personal
        information that you knowingly and willingly provide to us by way of
        surveys, completed membership forms, and emails. It is the intent of
        this site to use personal information only for the purpose for which it
        was requested, and any additional uses specifically provided for in this
        Policy.
      </p>

      <h2 className='pl-5'>Why We Collect Information and For How Long</h2>

      <p className='pl-5 pt-5'>
        We are collecting your data for several reasons:
      </p>
      <ul id='l2'>
        <li data-list-text='●'>
          <p className='pt-5 l2-li'>
            To better understand your needs and provide you with the services
            you have requested;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            To fulfill our legitimate interest in improving our services and
            products;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            To send you promotional emails containing information we think you
            may like when we have your consent to do so;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            To contact you to fill out surveys or participate in other types of
            market research, when we have your consent to do so;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            To customize our website according to your online behavior and
            personal preferences.
          </p>
        </li>
      </ul>
      <p className='pl-5'>
        The data we collect from you will be stored for no longer than
        necessary. The length of time we retain said information will be
        determined based upon the following criteria: the length of time your
        personal information remains relevant; the length of time it is
        reasonable to keep records to demonstrate that we have fulfilled our
        duties and obligations; any limitation periods within which claims might
        be made; any retention periods prescribed by law or recommended by
        regulators, professional bodies or associations; the type of contract we
        have with you, the existence of your consent, and our legitimate
        interest in keeping such information as stated in this Policy.
      </p>

      <h2 className='pl-5-left'>Use of Information Collected</h2>

      <p className='pt-5 pl-5-left'>
        KoinPurse.com does not now, nor will it in the future, sell, rent or
        lease any of its customer lists and/or names to any third parties.
      </p>

      <p className='pl-5'>
        KoinPurse.com may collect and may make use of personal information to
        assist in the operation of our website and to ensure delivery of the
        services you need and request. At times, we may find it necessary to use
        personally identifiable information as a means to keep you informed of
        other possible products and/or services that may be available to you
        from KoinPurse.com and its subsidiaries.
      </p>

      <p className='pl-5'>
        KoinPurse.com and its subsidiaries may also be in contact with you with
        regards to completing surveys and/or research questionnaires related to
        your opinion of current, potential, or future services that may be
        offered.
      </p>

      <p className='pl-5'>
        KoinPurse.com may feel it necessary, from time to time, to contact you
        on behalf of our other external business partners with regards to a
        potential new offer which may be of interest to you. If you consent or
        show interest in presented offers, then, at that time, specific
        identifiable information, such as name, email address and/or telephone
        number, may be shared with the third party.
      </p>

      <h2 className='pl-5'>Disclosure of Information</h2>

      <p className='pl-5 pt-5'>
        KoinPurse.com may not use or disclose the information provided by you
        except under the following circumstances:
      </p>
      <ul id='l2'>
        <li data-list-text='●'>
          <p className='pt-5 l2-li'>
            as necessary to provide services or products you have ordered;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            in other ways described in this Policy or to which you have
            otherwise consented;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            in the aggregate with other information in such a way so that your
            identity cannot reasonably be determined;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            as required by law, or in response to a subpoena or search warrant;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            to outside auditors who have agreed to keep the information
            confidential;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            as necessary to enforce the Terms of Service on our website;
          </p>
        </li>
        <li data-list-text='●'>
          <p className='l2-li'>
            as necessary to maintain, safeguard and preserve all the rights and
            property of KoinPurse.com.
          </p>
        </li>
      </ul>
      <h2 className='pt-5 pl-5-left'>Non-Marketing Purposes</h2>

      <p className='pt-5 pl-5-left'>
        KoinPurse.com greatly respects your privacy. We do maintain and reserve
        the right to contact you if needed for non-marketing purposes (such as
        bug alerts, security breaches, account issues, and/or changes in
        KoinPurse.com products and services, or changes to this Policy). In
        certain circumstances, we may use our website, newspapers, or other
        public means to post a notice.
      </p>

      <h2 className='pl-5-left'>Children under the age of 13</h2>

      <p className='pt-5 pl-5-left'>
        KoinPurse.com's website is not directed to, and does not knowingly
        collect personal identifiable information from, children under the age
        of thirteen (13). If it is determined that such information has been
        inadvertently collected on anyone under the age of thirteen (13), we
        shall immediately take the necessary steps to ensure that such
        information is deleted from our system's database, or in the
        alternative, that verifiable parental consent is obtained for the use
        and storage of such information. Anyone under the age of thirteen (13)
        must seek and obtain parent or guardian permission to use this website.
      </p>

      <h2 className='pl-5-left'>Unsubscribe or Opt-Out</h2>

      <p className='pt-5 pl-5-left'>
        All users and visitors to our website have the option to discontinue
        receiving communications from us by way of email or newsletters. To
        discontinue or unsubscribe from our website please send an email that
        you wish to unsubscribe to{' '}
        <a
          href='mailto:gotkoin@yahoo.com'
          className='s3'
          target='_blank'
          rel='noreferrer'
        >
          gotkoin@yahoo.com
        </a>
        . If you wish to unsubscribe or opt-out from any third-party websites,
        you must go to that specific website to unsubscribe or opt-out.
        KoinPurse.com will continue to adhere to this Policy with respect to any
        personal information previously collected.
      </p>

      <h2 className='pl-5-left'>Links to Other Websites</h2>

      <p className='pt-5 pl-5-left'>
        Our website does contain links to affiliate and other websites.
        KoinPurse.com does not claim nor accept responsibility for any privacy
        policies, practices and/or procedures of other websites. Therefore, we
        encourage all users and visitors to be aware when they leave our website
        and to read the privacy statements of every website that collects
        personally identifiable information. This Policy applies only and solely
        to the information collected by our website.
      </p>

      <h2 className='pl-5-left'>Notice to European Union Users</h2>

      <p className='pt-5 pl-5-left'>
        KoinPurse.com's operations are located primarily in the United States.
        If you provide information to us, the information will be transferred
        out of the European Union (EU) and sent to the United States. (The
        adequacy decision on the EU-US Privacy became operational on August 1,
        2016. This framework protects the fundamental rights of anyone in the EU
        whose personal data is transferred to the United States for commercial
        purposes. It allows the free transfer of data to companies that are
        certified in the US under the Privacy Shield.) By providing personal
        information to us, you are consenting to its storage and use as
        described in this Policy.
      </p>

      <h2 className='pl-5-left'>Security</h2>

      <p className='pt-5 pl-5-left'>
        KoinPurse.com takes precautions to protect your information. When you
        submit sensitive information via the website, your information is
        protected both online and offline. Wherever we collect sensitive
        information (e.g. credit card information), that information is
        encrypted and transmitted to us in a secure way. You can verify this by
        looking for a lock icon in the address bar and looking for "https" at
        the beginning of the address of the webpage.
      </p>

      <p className='pl-5'>
        While we use encryption to protect sensitive information transmitted
        online, we also protect your information offline. Only employees who
        need the information to perform a specific job (for example, billing or
        customer service) are granted access to personally identifiable
        information. The computers and servers in which we store personally
        identifiable information are kept in a secure environment. This is all
        done to prevent any loss, misuse, unauthorized access, disclosure or
        modification of the user's personal information under our control.
      </p>
      <p className='pl-5 pt-5'>
        The Company also uses Secure Socket Layer (SSL) for authentication and
        private communications to build users' trust and confidence in the
        internet and website use by providing simple and secure access and
        communication of credit card and personal information.
      </p>

      <h2 className='pl-5-left'>Acceptance of Terms</h2>

      <p className='pt-5 pl-5-left'>
        By using this website, you are hereby accepting the terms and conditions
        stipulated within this Privacy Policy Agreement. If you are not in
        agreement with our terms and conditions, then you should refrain from
        further use of our sites. In addition, your continued use of our website
        following proper notification or the posting of any updates or changes
        to our terms and conditions, shall mean that you agree and accept such
        changes.
      </p>

      <h2 className='pl-5-left'>How to Contact Us</h2>

      <p className='pt-5 pl-5-left'>
        If you have any questions or concerns regarding this Privacy Policy as
        it relates to our website, please feel free to contact us at the
        following email, telephone number or mailing address:
      </p>

      <p className=' pt-5 pl-5-left p-wrapp'>
        <span>
          <span className='s-1'>Email: </span>
          <a
            href='mailto:gotkoin@yahoo.com'
            className='s3'
            target='_blank'
            rel='noreferrer'
          >
            gotkoin@yahoo.com
          </a>
        </span>
        <span className='s1 pt-5'>Mailing Address: </span>
        <span>KoinPurse.com</span>
        <span>PO Box 460383</span>
        <span>San Antonio, Texas</span>
        <span>78246</span>
      </p>
      <h3 className='pt-5 pl-5-left'>GDPR Disclosure:</h3>
      <p className='s8 pt-5 pl-5-left'>
        If you answered "yes" to the question Does your website comply with the
        General Data Protection Regulation ("GDPR")? then the Privacy Policy
        above includes language that is meant to account for such compliance.
        Nevertheless, in order to be fully compliant with GDPR regulations your
        company must fulfill other requirements such as: (i) doing an assessment
        of data processing activities to improve security; (ii) have a data
        processing agreement with any third party vendors; (iii) appoint a data
        protection officer for the company to monitor GDPR compliance; (iv)
        designate a representative based in the EU under certain circumstances;
        and (v) have a protocol in place to handle a potential data breach. For
        more details on how to make sure your company is fully compliant with
        GDPR, please visit the official website at
        <a
          href='https://gdpr.eu/'
          className='a'
          target='_blank'
          rel='noreferrer'
        >
          {' '}
          https://gdpr.eu
        </a>
        <span className='s4'>
          . FormSwift and its subsidiaries are in no way responsible for
          determining whether or not your company is in fact compliant with GDPR
          and takes no responsibility for the use you make of this Privacy
          Policy or for any potential liability your company may face in
          relation to any GDPR compliance issues.
        </span>
      </p>

      <h3 className='pl-5-left'>COPPA Compliance Disclosure:</h3>

      <p className='s4 pl-5-left'>
        This Privacy Policy presumes that your website is <u>not</u> directed at
        children under the age of 13 and does <u>not</u> knowingly collect
        personal identifiable information from them or allow others to do the
        same through your site. If this is not true for your website or online
        service and you do collect such information (or allow others to do so),
        please be aware that you must be compliant with all COPPA regulations
        and guidelines in order to avoid violations which could lead to law
        enforcement actions, including civil penalties.
      </p>

      <p className='s4 pl-5-left'>
        In order to be fully compliant with COPPA your website or online service
        must fulfill other requirements such as: (i) posting a privacy policy
        which describes not only <i>your </i>practices, but also the practices
        of any others collecting personal information on your site or service —
        for example, plug-ins or ad networks; (ii) include a prominent link to
        your privacy policy anywhere you collect personal information from
        children; (iii) include a description of parental rights (e.g. that you
        won't require a child to disclose more information than is reasonably
        necessary, that they can review their child's personal information,
        direct you to delete it, and refuse to allow any further collection or
        use of the child's information, and the procedures to exercise their
        rights); (iv) give parents "direct notice" of your information practices
        before collecting information from their children; and (v) obtain the
        parents' "verifiable consent" before collecting, using or disclosing
        personal information from a child. For more information on the
        definition of these terms and how to make sure your website or online
        service is fully compliant with COPPA please visit
        <a
          href='https://www.ftc.gov/tips-advice/business-center/guidance/childrens-online-privacy-protection-rule-six-step-compliance'
          className='a'
          target='_blank'
          rel='noreferrer'
        >
          {' '}
          https://www.ftc.gov/tips-advice/business-center/guidance/childrens-online-privacy-protection-rule-six-step-{' '}
        </a>
        compliance. FormSwift and its subsidiaries are in no way responsible for
        determining whether or not your company is in fact compliant with{' '}
        <span className='s4'>
          COPPA and takes no responsibility for the use you make of this Privacy
          Policy or for any potential liability your company may face in
          relation to any COPPA compliance issues.
        </span>
      </p>

      <p className='s8 pl-5-left'>
        <span className='s-1'>
          California Consumer Privacy Act ("CCPA") Compliance Disclosure:
        </span>{' '}
        The CCPA is a collection of privacy laws enacted by the California
        legislature which is meant to protect Californians' personal data from
        being sold or used without their consent. The requirements of the CCPA
        apply to, among others, businesses that (i) have a gross annual revenue
        of at least $25 million dollars; or (ii) buys, receives, or sells
        personal information of 50,000 or more Californian consumers, households
        or devices; or (iii) derives 50% or more of its revenue from the sale of
        personal information. If your business meets these requirements or is
        otherwise based out of California, you must comply with the requirements
        of the CCPA or risk facing fines, sanctions and civil penalties. For
        more information about CCPA and its requirements please visit
        <a
          href='https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375'
          className='a'
          target='_blank'
          rel='noreferrer'
        >
          {' '}
          https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?
          bill_id=201720180AB375{' '}
        </a>
        . Please note that the CCPA is scheduled to be updated by January 2023
        with new requirements and definitions so please be sure to check those
        regulations periodically to make sure your Privacy Policy is up to date.
        FormSwift and its subsidiaries are in no way responsible for determining
        whether or not your company is in fact compliant with the CCPA or any
        other privacy regulations, and takes no responsibility for the use you
        make of this Privacy Policy or for any potential liability your company
        may face in relation to any compliance issues.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
