import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUser, subscribe } from '../../redux/auth/authThunk';
import QrReader from '../../components/QrReader';
import SendKoins from '../../components/SendKoins';
import PayForm from '../../components/PaymentForm/PaymentForm';
import WalletIcon from '../../assets/svg/Subtract.svg';
import QrCode from '../../assets/svg/QrCode.svg';
import SendIcon from '../../assets/svg/ArrowUpRightcolor.svg';
import WrapperCoin from '../../assets/svg/wrappercoin.svg';
import SubmitIcon from '../../assets/svg/ArrowRight.svg';
import './KoinPurse.scss';
import { Card } from '../../models/Models';

interface Props {}

export interface UserT {
  created_at: string;
  email: string;
  full_name: string;
  koins: number;
  qr: string;
  updated_at: string;
  uuid: string;
}

const KoinPurse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { auth } = useAppSelector((state: any) => state.auth);
  const [user, setUser] = useState<UserT | null>(null);
  const [sendkoins, setSendkoins] = useState(false);
  const [scan, setScan] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleSendKoinsClose = () => {
    setSendkoins(false);
  };

  const handleSendKoins = () => {
    setSendkoins(true);
  };

  const handleScanClose = () => {
    setScan(false);
  };

  const handleScan = () => {
    setScan(true);
  };

  const handleSubscribe = (card: Card) => {
    dispatch(
      subscribe({
        accessToken: auth?.token,
        source_id: card.source_id,
        card_number: card.card_number,
        card_type: card.card_type
      })
    ).then((res: any) => {
      if (res.payload?.status === 'OK') {
        dispatch(getUser({ uuid: auth?.user?.uuid, token: auth?.token }));
        navigate('/koin-message');
      }
    });
  };

  useEffect(() => {
    if (auth?.user) {
      setUser(auth?.user);
      if (!auth?.user?.is_account_confirmed) navigate('/confirm-phone');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const canSubscribe = () => {
    let now = new Date();
    let dateEntered = new Date(auth?.user?.unsubscribe_date).getTime();
    let sixMonthBeforeNow = new Date(now).setTime(
      now.getTime() - 3 * 28 * 24 * 60 * 60
    );

    return dateEntered > sixMonthBeforeNow;
  };

  const setPaymentTokenHandler = (card: Card) => {
    if (card) {
      setShowForm(false);
      handleSubscribe(card);
    }
  };

  return (
    <>
      {user && !sendkoins && !scan && (
        <div className='koin-purse-container'>
          {showForm && <PayForm setPaymentToken={setPaymentTokenHandler} />}
          {auth?.user?.is_subscribed && (
            <h1 className='title'>{user?.full_name}</h1>
          )}
          {auth?.user?.is_subscribed && (
            <span className='email-profile'>{user?.email}</span>
          )}
          <div className='wrapper-coin'>
            <img className='bgwrappcoin' src={WrapperCoin} alt='WrapperCoin' />
            <div className='wallet-icon'>
              <img src={WalletIcon} alt='' />
            </div>
            <div className='balance-container'>
              <span className='balance-value'>{user?.koins} Koins</span>
              <span className='balance-title'>Your Balance</span>
              <span className='balance-text'>
                Koin limit is {process.env.REACT_APP_KOINS_LIMIT}
              </span>
            </div>
          </div>
          {!auth?.user?.is_subscribed && !canSubscribe() && (
            <div className='linear-gradient-button'>
              <button
                type='submit'
                className='submit-button'
                onClick={() => setShowForm(true)}
              >
                Subscribe
                <img src={SubmitIcon} alt='' />
              </button>
            </div>
          )}

          {auth?.user?.is_subscribed && (
            <div className='koin-btns-container'>
              <div onClick={handleScan} className='btn-item'>
                <img src={QrCode} alt='QrCode' />
                Scan QR-Code
              </div>
              <div className='btn-item' onClick={handleSendKoins}>
                <img src={SendIcon} alt='SendIcon' />
                Send Koins
              </div>
            </div>
          )}
          {auth?.user?.is_subscribed && (
            <div>
              <Link to={'/privacy'} className='privacy-policy'>
                Privacy Policy
              </Link>
              <Link className='terms' to={'/terms'}>
                Terms and Conditions
              </Link>
            </div>
          )}
        </div>
      )}
      {sendkoins && <SendKoins handleSendKoinsClose={handleSendKoinsClose} />}
      {scan && <QrReader handleScanClose={handleScanClose} />}
    </>
  );
};

export default KoinPurse;
