import AccountMessage from '../../components/AccountMessage';
import PasswordBg from '../../assets/img/passwordbg.png';
import './UserConfirmed.scss';

interface Props {}

const UserConfirmed: React.FC<Props> = () => {
  return (
    <AccountMessage
      title={'You have successfully confirmed your email.'}
      link='/login'
      img={PasswordBg}
    />
  );
};

export default UserConfirmed;
