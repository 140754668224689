import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordProps } from "../../models/Auth";
import { forgotPassword } from "../../redux/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { hasError, notifySuccess } from "../../assets/helpers/helpers";
import Envelope from "../../assets/svg/EnvelopeOpen.svg";
import SubmitIcon from "../../assets/svg/ArrowRight.svg";
import background from "../../assets/svg/forgotbg.svg";
import "./RessetPassword.scss";

interface Props {}

const ForgotPassword: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector((state: any) => state.auth);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState<ForgotPasswordProps>({
    email: "",
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(forgotPassword(formData)).then((res) => {
      if (res?.payload?.status === "OK") {
        notifySuccess("Email has been sent to the account on file.");
        setFormData({
          email: "",
        });
        navigate("/");
      }
      setErrors(typeof error === "string" ? [error] : error);
    });
  };

  return (
    <div className="resset-pass-container">
      <img className="forgotbgimg" src={background} alt="background" />
      <div className="form-conatiner">
        <h4 className="title">Forgot Password</h4>

        <form onSubmit={handleSubmit}>
          <div
            className={`inputs-field ${
              hasError(errors, "email") ? "error" : ""
            }`}
          >
            <img src={Envelope} alt="" />
            <input
              placeholder="Email Address"
              type="text"
              id="email"
              value={formData.email}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value.toLocaleLowerCase(),
                });
                setErrors((prev) =>
                  prev?.filter((err: any) => err.key !== "email")
                );
              }}
            />
          </div>
          <div className="linear-gradient-button">
            <button type="submit" className="submit-button">
              Next
              <img src={SubmitIcon} alt="" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
