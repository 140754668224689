import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendConfirmationCode } from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ArrowBack from '../../assets/svg/arrowback.svg';
import './ConfirmPhone.scss';
import PhoneInputWithCountrySelect, {
  parsePhoneNumber
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface Props {}

const ConfirmPhone: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector(state => state.auth);
  const [phone, setPhoneNumber] = useState(auth?.user?.phone ?? '');

  const sendCode = (e: any) => {
    e.preventDefault();

    dispatch(
      sendConfirmationCode({
        phone: phone,
        email: auth?.user?.email ?? ''
      })
    ).then((res: any) => {
      if (res.payload?.status === 'OK') {
        localStorage.setItem('phone', phone);
        localStorage.setItem('email', auth?.user?.email);
        navigate('/confirm-code');
      }
    });
  };

  return (
    <>
      <div className='confirm-phone-container'>
        <div className='back-btn' onClick={() => navigate('/sign-up')}>
          <img src={ArrowBack} alt='' />
        </div>
        <div className='confirm-phone-message-top'>
          <div className='confirm-phone-message-title'>Activate account</div>
          <div className='confirm-phone-message-text'>
            Your Email Address:
            <br />
            {auth?.user?.email}
          </div>
        </div>
        <div className='confirm-phone-message-separator'></div>
        <form className='confirm-phone-message-bottom'>
          <label>Phone Number:</label>{' '}
          <div className='inputs-field '>
            <PhoneInputWithCountrySelect
              defaultCountry={phone ? parsePhoneNumber(phone)?.country : 'US'}
              international
              countryCallingCodeEditable={false}
              value={phone}
              onChange={e => {
                setPhoneNumber(e);
              }}
            />
          </div>
        </form>
        <div className='btn-section'>
          <div className='continue-btn' onClick={e => sendCode(e)}>
            Get code
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default ConfirmPhone;
