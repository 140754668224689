import SubmitIcon from '../../assets/svg/ArrowRight.svg';
import WarningIcon from '../../assets/svg/Warning.svg';
import './Warning.scss';

interface Props {
  handleSubmit: (e: any) => void;
  handleBack: () => void;
}

const Warning: React.FC<Props> = ({ handleBack, handleSubmit }: Props) => {
  return (
    <>
      <div className='warning-container full-container'>
        <div className='warning-block'>
          <img src={WarningIcon} alt='' />
          <h1 className='title'>Warning</h1>
          <p>All transfers are final and can't be reversed</p>
        </div>
        <div className='btns-container'>
          <div className='linear-gradient-button'>
            <button
              type='submit'
              className='submit-button'
              onClick={() => handleBack()}
            >
              Back
              <img src={SubmitIcon} alt='' />
            </button>
          </div>
          <div className='linear-gradient-button'>
            <button
              type='submit'
              className='submit-button'
              onClick={e => handleSubmit(e)}
            >
              Send
              <img src={SubmitIcon} alt='' />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warning;
